import React, { Component } from 'react';
import good from "./img/checkmark.png";
import bad from "./img/x.png";

export default class ReportCard extends Component {
    render(){
        return(
            <div className="report">
                <img src= { this.props.src } alt="left" className={this.props.type} />
                <div  className={((this.props.authentic) ? "Real" : "fake" ) + " result " + this.props.type}>
                    <img alt={(this.props.authentic) ? "Real" : "fake"} src={(this.props.authentic) ? good : bad} />
                </div>
            </div>
        );
    }
}