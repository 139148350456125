import React, { Component } from 'react';
import ChoiceCard from './ChoiceCard.js';
import ReportCard from './ReportCard.js';
import update from 'immutability-helper';
// import Particles from 'react-particles-js';

// import start from './img/start.png';
import restart from './img/restart.png';
// import logo from './img/mitek_logo.svg';
import splash from './img/fake_background.png';

import i1real from './img/1_real.png';
import i1fake from './img/1_fake.png';
import i1fakeh from './img/1_fake_highlighted.png';
import i2real from './img/2_real.jpg';
import i2fake from './img/2_fake.jpg';
import i3real from './img/3_real.png';
import i3fake from './img/3_fake.png';
import i3fakeh from './img/3_fake_highlighted.png';
import i4real from './img/4_real.jpg';
import i4fake from './img/4_fake.jpg';
import i5real from './img/5_real.png';
import i5fake from './img/5_fake.png';
import i5fakeh from './img/5_fake_highlighted.png';
import i6real from './img/6_real.jpg';
import i6fake from './img/6_fake.jpg';

import './App.scss';

const images = [
    {
        "real": i5real,
        "fake": i5fake,
        "highlighted": i5fakeh
    },
    {
        "real": i6real,
        "fake": i6fake,
        "highlighted": i6fake
    },
    {
        "real": i1real,
        "fake": i1fake,
        "highlighted": i1fakeh
    },
    {
        "real": i2real,
        "fake": i2fake,
        "highlighted": i2fake
    },
    {
        "real": i3real,
        "fake": i3fake,
        "highlighted": i3fakeh
    },
    {
        "real": i4real,
        "fake": i4fake,
        "highlighted": i4fake
    }
];

const initialState = {
    screen: -1,
    round: 0,
    dungeon: {},
    guesses: {},
    transitioningClass: "",
    score: 0
};

const anims = 700;

class App extends Component {

    constructor(props) {
        super(props);
        this.state = initialState;
        this.state.dungeon = this.getNewDungeon()
    }

    reset() {
        this.setState(initialState);
        this.setState({
            dungeon: this.getNewDungeon()
        });
    }

    getNewDungeon(){
        var dungeon = [];
        for(var i in images) {
            let left = Math.round(Math.random()) === 1 ? "real" : "fake";
            let right = left === "real" ? "fake" : "real";
            let type = i%2 === 0 ? "id" : "photo";
            dungeon[i] = {
                selected: null,
                type: type,
                left: {
                    image: images[i][left],
                    highlighted: images[i]['highlighted'],
                    authentic: left === "real",
                },
                right: {
                    image: images[i][right],
                    highlighted: images[i]['highlighted'],
                    authentic: right === "real",
                },
            };

        }
        return dungeon
    }

    renderStartScreen(){
        return (
            <div className="screen start-screen">
                <div className="screen-inner">
                    <img className="splash" src={splash} alt="Mitek" onClick={() => this.goToScreen()} />
                </div>
            </div>
        )
    }

    renderGameScreen(){
        let room =  this.state.dungeon[this.state.screen];
        let previousRoom = this.state.dungeon[(this.state.screen - 1)];

        return (
            <div className="screen game-screen">

                <div className="help">
                    {(room.type === "photo") &&
                        <p>Select the real owner</p>
                    }

                    {(room.type === "id") &&
                    <p>Select the authentic ID</p>
                    }

                </div>

                <div className="choices">

                    <ChoiceCard
                        onClick={() => this.selectCard("left")}
                        position="left"
                        src={room["left"]["image"]}
                        selected={(room.selected === "left")}
                        type={room["type"]}
                    />
                    <ChoiceCard
                        onClick={() => this.selectCard("right")}
                        position="right"
                        src={room["right"]["image"]}
                        selected={(room.selected === "right")}
                        type={room["type"]}
                    />
                    {(room.type === "photo") &&
                    <ChoiceCard
                        position="center"
                        src={previousRoom[previousRoom["selected"]]["image"]}
                        selected="true"
                        type="id"
                    />
                    }

                </div>

                <div className="footer">
                    <div className="progress">{this.state.screen + 1} of {this.state.dungeon.length }</div>
                </div>
            </div>
        )
    }

    renderEndScreen(){

        var rating = "bad";
        if (this.state.score === 6) rating = "great";
        else if (this.state.score >= 4) rating = "good";

        return (
            <div className="screen end-screen">

                <div className="score">
                    <span className="label">Score: </span>
                    <span className={"value " + rating}>{this.state.score}/6</span>
                </div>

                <div className="reports">
                    {this.state.dungeon.map((room, index) => (
                        <ReportCard
                            src={ room[room["selected"]]["authentic"] ?
                                room[room["selected"]]["image"] :
                                room[room["selected"]]["highlighted"]}
                            authentic={ room[room["selected"]]["authentic"]}
                            type={ room["type"]}
                        />
                    ))}
                </div>
                <div className="footer">
                    <button className="restart" onClick={() => this.reset()}><img src={restart} alt="Restart" /></button>
                </div>
            </div>
        )
    }

    selectCard(cardPosition){
        let oldScreen = this.state.screen;
        var score = this.state.score;

        // Total up the scores just b/c it's easy to do here
        if (this.state.dungeon[oldScreen][cardPosition]['authentic']) {
            score++;
        }

        let newState = update(this.state, {
            score: { $set: score},
            dungeon:{
                [oldScreen]: {
                    selected: { $set: cardPosition}
                }
            },
        });

        this.setState(newState);
        this.goToScreen()
    }

    renderCurrentScreen() {
        switch(this.state.screen) {
            case -1:
                return this.renderStartScreen();
            case 6:
                return this.renderEndScreen();
            default:
                return this.renderGameScreen();
        }
    }

    render() {
        return (
            <div className={"container " + this.state.transitioningClass}>
                <div className="blocker" />
                { this.renderCurrentScreen() }
            </div>
        );
    }

    goToScreen(screenNumber){

        // The screen number we're going to
        if (screenNumber === undefined) screenNumber = this.state.screen + 1;

        this.setState({
            transitioningClass: "transitioning-out"
        });

        setTimeout(() => {
            this.setState({
                screen: screenNumber,
                transitioningClass: "transitioning-in"
            });
        }, anims);

        setTimeout(() => {
            this.setState({
                transitioningClass: ""
            });
        }, anims*2)

    }
}

export default App;
