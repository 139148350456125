import React, { Component } from 'react';

export default class ChoiceCard extends Component {

    render(){
        return(
            <div onClick={this.props.onClick} className={ "choice " + this.props.position + " " + this.props.type + " " + ( (this.props.selected) ? "chosen" : "not-chosen") }>
                <img src= { this.props.src } alt={this.props.position} />
                {/*<div className="choice-button">{(this.props.selected) ? "chosen" : "not-chosen"}</div>*/}
                <span>{this.props.real}</span>
            </div>
        );
    }
};
